import React, { useState, useEffect } from 'react';
import { TimetableDataTable } from '../../../components/Teacher/AdditionalResponsibility/TimetableDataTable';
import CreateTimeTable from '../../../components/Teacher/AdditionalResponsibility/CreateTimeTable';
import useApi from '../../../api/useApi';
import { SUBJECT } from '../../../api/urls/institute';
import { ALL_CLASSES_TIMETABLE, TIMETABLE_DELETE } from '../../../api/urls/timeTable';
import { GET_TEACHING_STAFF } from '../../../api/urls/user';

function TimetableIncharge() {
  const [openCreate, setOpenCreate] = useState(false);

  const [prePrimary, setPrePrimary] = useState([]);
  const [primary, setPrimary] = useState([]);
  const [middle, setMiddle] = useState([]);
  const [secondary, setSecondary] = useState([]);
  const [seniorSecondary, setSeniorSecondary] = useState([]);
  const [graduation, setGraduation] = useState([]);
  const [staff, setStaff] = useState([]);
  const [subjects, setSubject] = useState([]);
  const [selectedClass, selectClass] = useState();

  const [classSections, getClassSections] = useApi();
  const [staffResponse, getTeachingStaff] = useApi();
  const [subjectResponse, getSubjects] = useApi();
  const [{ isLoading, isError, data, status }, deleteTimetable] = useApi();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    getClassSections(ALL_CLASSES_TIMETABLE, 'GET');
    getTeachingStaff(GET_TEACHING_STAFF, 'GET');
  }, []);

  useEffect(() => {
    if (!staffResponse.isLoading && !staffResponse.isError && staffResponse.data) {
      setStaff(staffResponse.data);
    }
  }, [staffResponse]);

  useEffect(() => {
    if (!subjectResponse.isLoading && !subjectResponse.isError && subjectResponse.data) {
      setSubject(subjectResponse.data);
    }
  }, [subjectResponse]);

  useEffect(() => {
    if (!classSections.isLoading && !classSections.isError && classSections.data && Array.isArray(classSections.data)) {
      const _prePrimary = [];
      const _primary = [];
      const _middle = [];
      const _secondary = [];
      const _seniorSecondary = [];
      const _graduation = [] ;
      classSections.data.forEach((x) => {
        if (x.classInfo.name.toLowerCase().includes('kg') || x.classInfo.name.toLowerCase().includes('nursery')
        || x.classInfo.name.toLowerCase().includes("k.g")  || x.classInfo.name.toLowerCase().includes("k.g.")
        || x.classInfo.name.toLowerCase().includes("pre-nursury")  || x.classInfo.name.toLowerCase().includes("nursury")
        || x.classInfo.name.toLowerCase().includes("l.k.g")  || x.classInfo.name.toLowerCase().includes("u.k.g")
        || x.classInfo.name.toLowerCase().includes("l.k.g.")  || x.classInfo.name.toLowerCase().includes("u.k.g.") 
        || x.classInfo.name.toLowerCase().includes("lkg")  || x.classInfo.name.toLowerCase().includes("ukg")
        || x.classInfo?.groupID == "1"
        // Pre-Nursery, Nursery, KG, LKG, and UKG
        ) {
          _prePrimary.push(x);
        } else if (x.classInfo.name.includes(11) || x.classInfo.name.includes(12)
          || x.classInfo?.groupID == "5" ) {
          _seniorSecondary.push(x);
        } else if (x.classInfo.name.includes(9) || x.classInfo.name.includes(10)
          || x.classInfo?.groupID == "4" ) {
          _secondary.push(x);
        } else if (x.classInfo.name.includes(6) || x.classInfo.name.includes(7) || x.classInfo.name.includes(8)
          || x.classInfo?.groupID == "3" ) {
          _middle.push(x);
        } else if (
          x.classInfo.name.includes(1) ||
          x.classInfo.name.includes(2) ||
          x.classInfo.name.includes(3) ||
          x.classInfo.name.includes(4) ||
          x.classInfo.name.includes(5) || x.classInfo?.groupID == "2"
        ) {
          _primary.push(x);
        } else if ( x.classInfo?.groupID == "6" ) {
          _graduation.push(x);
        }
        // console.log(" -- _middle : ", x.classInfo?.groupID == "3" , x.classInfo?.groupID  );
      });

      setPrePrimary(_prePrimary);
      setPrimary(_primary);
      setMiddle(_middle);
      setSecondary(_secondary);
      setSeniorSecondary(_seniorSecondary);
      setGraduation(_graduation);
      // console.log(" -- _middle : ", _middle );
    }
  }, [classSections]);

  const handleCreate = (x) => {
    selectClass(x);
    setOpenCreate(true);
  };

  useEffect(() => {
    if (selectedClass) getSubjects(SUBJECT(selectedClass.classInfo._id), 'GET');
  }, [selectedClass]);

  const refreshData = () => {
    getClassSections(ALL_CLASSES_TIMETABLE, 'GET');
  };

  const handleDelete = (id) => {
    if (window.confirm('Are you sure you want to delete?')) deleteTimetable(TIMETABLE_DELETE(id), 'DELETE');
  };

  useEffect(() => {
    if (!isLoading && !isError && status == 200) {
      refreshData();
      alert('Success!');
    }

    if (isError && data) {
      alert(data || 'Failed to delete');
    }
  }, [isLoading, isError, data, status]);

  return (
    <>
      {openCreate && (
        <CreateTimeTable
          setOpenCreate={setOpenCreate}
          staff={staff}
          subjects={subjects}
          selectedClass={selectedClass}
          refreshData={refreshData}
          isView={selectedClass?.timeSlot.length > 0}
        />
      )}

      <div className="relative w-full md:w-4/5 pb-10">
        {/* Pre Primary */}
        <TimetableDataTable
          title={'Pre Primary'}
          setOpenCreate={setOpenCreate}
          classes={prePrimary}
          handleCreate={handleCreate}
          handleDelete={handleDelete}
        />

        {/* Primary */}
        <TimetableDataTable
          setOpenCreate={setOpenCreate}
          title={'Primary'}
          classes={primary}
          handleCreate={handleCreate}
          handleDelete={handleDelete}
        />

        {/* Middle */}
        <TimetableDataTable
          title={'Middle'}
          classes={middle}
          setOpenCreate={setOpenCreate}
          handleCreate={handleCreate}
          handleDelete={handleDelete}
        />

        {/* Secondary */}
        <TimetableDataTable
          title={'Secondary'}
          classes={secondary}
          setOpenCreate={setOpenCreate}
          handleCreate={handleCreate}
          handleDelete={handleDelete}
        />

        {/* Senior - Secondary */}
        <TimetableDataTable
          title={'Senior - Secondary'}
          classes={seniorSecondary}
          setOpenCreate={setOpenCreate}
          handleCreate={handleCreate}
          handleDelete={handleDelete}
        />

        {/* Graduation */}
        <TimetableDataTable
          title={'Graduation'}
          classes={graduation}
          setOpenCreate={setOpenCreate}
          handleCreate={handleCreate}
          handleDelete={handleDelete}
        />
      </div>
    </>
  );
}

export default TimetableIncharge;
