import React, { useState, useEffect, useRef } from 'react';
import ClassResult from '../../../components/Teacher/AdditionalResponsibility/ClassResult';
import ExamDates from '../../../components/Teacher/AdditionalResponsibility/ExamDates';
import ExaminationInchargeClassTable from '../../../components/Teacher/AdditionalResponsibility/ExaminationInchargeClassTable';
import ExamScheme from '../../../components/Teacher/AdditionalResponsibility/ExamScheme';
import ResultReleaseDate from '../../../components/Teacher/AdditionalResponsibility/ResultReleaseDate';
import SubjectMarks from '../../../components/Teacher/AdditionalResponsibility/SubjectMarks';
import DropDown from '../../../components/UI/DropDown';
import { CLASS_SECTIONS } from '../../../api/urls/institute';
import { EXAM_DATES, DELETE_EXAM_DATES } from '../../../api/urls/test';
import { ALL_EXAM_PATTERNS } from '../../../api/urls/institute';
import useApi from '../../../api/useApi';
import ExcelExportBtn from '../../../components/UI/ExcelExportBtn';

function ExaminationIncharge() {
  const [openClassResult, setOpenClassResult] = useState(false);
  const [selectedSubjectForMarks, selectSubjectFrrMarks] = useState(false);
  const [openResultDate, setOpenResultDate] = useState(false);
  const [openExamDates, setOpenExamDates] = useState(false);
  const [openExamScheme, setOpenExamScheme] = useState(false);
  const [selectedClass, selectClass] = useState();
  const [prePrimary, setPrePrimary] = useState([]);
  const [primary, setPrimary] = useState([]);
  const [middle, setMiddle] = useState([]);
  const [secondary, setSecondary] = useState([]);
  const [seniorSecondary, setSeniorSecondary] = useState([]);
  const [graduation, setGraduation] = useState([]);
  const [category, setCategory] = useState('');
  const [allClasses, setAllClasses] = useState([]);
  const [csvFile, setCsvFile] = useState();
  const [csvArray, setCsvArray] = useState([]);
  const [selectedClassForCSV, setSelectedClassFOrCSV] = useState();
  const [selectedClassForDates, selectClassForDates] = useState();
  const [filter, setFilter] = useState();
  const csvTemplate = [
    'Subject',
    'SubjectCode',
    'Unit Test-1',
    'Unit Test-2',
    'Term Exam-1',
    'Unit Test-3',
    'Term Exam-2',
  ];

  const csvRef = useRef();

  const [classSectionResponse, getClassSections] = useApi();
  const [postDatesResponse, postDates] = useApi();
  const [deleteDatesResponse, deleteDates] = useApi();
  const [examSchemas, getExamSchemas] = useApi();

  useEffect(() => {
    getClassSections(CLASS_SECTIONS, 'GET');
    getExamSchemas(ALL_EXAM_PATTERNS, 'GET');
  }, []);

  useEffect(() => {
    if (!classSectionResponse.isLoading && !classSectionResponse.isError && classSectionResponse.data) {
      const _prePrimary = [];
      const _primary = [];
      const _middle = [];
      const _secondary = [];
      const _seniorSecondary = [];
      const _graduation = [] ;
      classSectionResponse?.data?.studentInfo?.forEach((x) => {
        if (x.class !== null) {
          if (x.class.toLowerCase().includes('kg') || x.class.toLowerCase().includes('nursery')
            || x.classInfo?.groupID == "1"  ) {
            _prePrimary.push(x);
          } else if (x.class.includes(11) || x.class.includes(12) || x.classInfo?.groupID == "5" ) {
            _seniorSecondary.push(x);
          } else if (x.class.includes(9) || x.class.includes(10) || x.classInfo?.groupID == "4"  ) {
            _secondary.push(x);
          } else if (x.class.includes(6) || x.class.includes(7) || x.class.includes(8) || x.classInfo?.groupID == "3"  ) {
            _middle.push(x);
          } else if (
            x.class.includes(1) ||
            x.class.includes(2) ||
            x.class.includes(3) ||
            x.class.includes(4) ||
            x.class.includes(5)
          ) {
            _primary.push(x);
          } else if ( x.classInfo?.groupID == "6" ) {
            _graduation.push(x);
          }
        }
      });

      setPrePrimary(_prePrimary);
      setPrimary(_primary);
      setMiddle(_middle);
      setSecondary(_secondary);
      setSeniorSecondary(_seniorSecondary);
      setGraduation(_graduation) ;

      setAllClasses([..._primary, ..._middle, ..._secondary, ..._seniorSecondary, ..._graduation]);
    }
  }, [classSectionResponse]);

  useEffect(() => {
    if (selectedClass) {
      setOpenClassResult(true);
    }
  }, [selectedClass]);

  const handleFileSelect = (x) => {
    csvRef.current.click();
    setSelectedClassFOrCSV(x);
  };

  useEffect(() => {
    if (csvFile) {
      submit();
    }
  }, [csvFile]);

  const submit = () => {
    const file = csvFile;
    const reader = new FileReader();

    reader.onload = function (e) {
      const text = e.target.result;
      processCSV(text);
    };

    reader.readAsText(file);
  };

  const processCSV = (str, delim = ',') => {
    const headers = str
      .slice(0, str.indexOf('\n'))
      .split(delim)
      .map((x) => x.replace('\r', ''));

    const rows = str.slice(str.indexOf('\n') + 1).split('\n');

    const newArray = rows.map((row) => {
      const values = row.split(delim).map((x) => x.replace('\r', '').replace(/['"]+/g, ''));

      const eachObject = headers.reduce((obj, header, i) => {
        obj[header] = values[i];
        return obj;
      }, {});
      return eachObject;
    });
    setCsvArray(newArray);
  };

  useEffect(() => {
    if (csvArray.length > 0) {
      const body = {
        examDates: csvArray,
        uniqueIndentity: selectedClassForCSV.class + '-' + selectedClassForCSV.section,
      };
      postDates(EXAM_DATES(selectedClassForCSV.classId, selectedClassForCSV.sectionId), 'POST', body);
    }
  }, [csvArray]);

  useEffect(() => {
    if (
      !postDatesResponse.isLoading &&
      !postDatesResponse.isError &&
      [200, 201, 202].includes(postDatesResponse.status)
    ) {
      getClassSections(CLASS_SECTIONS, 'GET');
      setSelectedClassFOrCSV(null);
      setCsvArray([]);
      setCsvFile(null);
      csvRef.current.value = null;
      alert('Success');
    }

    if (!postDatesResponse.isLoading && postDatesResponse.isError) {
      alert('Failed');
    }
  }, [postDatesResponse]);

  const handleDelete = (x) => {
    deleteDates(DELETE_EXAM_DATES(x.class + '-' + x.section), 'DELETE');
  };

  useEffect(() => {
    if (
      !deleteDatesResponse.isLoading &&
      !deleteDatesResponse.isError &&
      [200, 201, 202].includes(deleteDatesResponse.status)
    ) {
      getClassSections(CLASS_SECTIONS, 'GET');
      alert('Success');
    }

    if (!deleteDatesResponse.isLoading && deleteDatesResponse.isError) {
      alert('Failed');
    }
  }, [deleteDatesResponse]);

  useEffect(() => {
    if (selectedClassForDates) {
      setOpenExamDates(true);
    } else {
      setOpenExamDates(false);
    }
  }, [selectedClassForDates]);

  const refreshList = () => {
    getClassSections(CLASS_SECTIONS, 'GET');
  };

  const refreshSchema = () => {
    getExamSchemas(ALL_EXAM_PATTERNS, 'GET');
  };

  let _classes = allClasses || [];
  if (filter && filter !== 'All') {
    _classes = allClasses.filter((x) => {
      if (filter === 'Pre - Primary') {
        return x.class.toLowerCase().includes('kg') || x.class.toLowerCase().includes('nursery') || x.classInfo?.groupID == "1" ;
      } else if (filter === 'Senior - Secondary') {
        return x.class.toLowerCase().includes('11') || x.class.toLowerCase().includes('12') || x.classInfo?.groupID == "5" ;
      } else if (filter === 'Secondary') {
        return x.class.toLowerCase().includes('9') || x.class.toLowerCase().includes('10') || x.classInfo?.groupID == "4" ;
      } else if (filter === 'Middle') {
        return (
          x.class.toLowerCase().includes('6') ||
          x.class.toLowerCase().includes('7') ||
          x.class.toLowerCase().includes('8') || x.classInfo?.groupID == "3"
        );
      } else if(filter === "Graduation" ) {
        return x.classInfo?.groupID == "6" ;
      }
      
      else {
        return (
          (x.class.toLowerCase().includes('1') &&
            !x.class.toLowerCase().includes('11') &&
            !x.class.toLowerCase().includes('12')) ||
          (x.class.toLowerCase().includes('2') && !x.class.toLowerCase().includes('12')) ||
          x.class.toLowerCase().includes('3') ||
          x.class.toLowerCase().includes('4') ||
          x.class.toLowerCase().includes('5')
        );
      }
    });
  }

  return (
    <>
      {selectedSubjectForMarks && (
        <SubjectMarks selectSubjectFrrMarks={selectSubjectFrrMarks} selectedSubjectForMarks={selectedSubjectForMarks} />
      )}
      {openResultDate && <ResultReleaseDate setOpenResultDate={setOpenResultDate} category={category} />}
      {openExamDates && (
        <ExamDates
          setOpenExamDates={setOpenExamDates}
          selectedClassForDates={selectedClassForDates}
          selectClassForDates={selectClassForDates}
        />
      )}
      {openExamScheme && (
        <ExamScheme
          setOpenExamScheme={setOpenExamScheme}
          category={category}
          examSchema={examSchemas?.data?.find((x) => x.category === category)}
          refresh={refreshSchema}
        />
      )}

      {openClassResult ? (
        <ClassResult
          handleClose={() => {
            setOpenClassResult(false);
            selectClass(null);
          }}
          selectSubjectFrrMarks={selectSubjectFrrMarks}
          selectedClass={selectedClass}
          category={category}
        />
      ) : (
        <div className="relative w-full md:w-4/5 pb-10">
          {/* Pre-Primary */}
          <ExaminationInchargeClassTable
            setOpenExamScheme={setOpenExamScheme}
            setOpenResultDate={setOpenResultDate}
            setOpenClassResult={setOpenClassResult}
            selectClass={selectClass}
            title={'Pre Primary'}
            classes={prePrimary}
            setCategory={setCategory}
            refresh={refreshList}
            examSchema={examSchemas?.data?.find((x) => x.category === 'Pre-Primary')}
          />

          {/* Primary */}
          <ExaminationInchargeClassTable
            setOpenExamScheme={setOpenExamScheme}
            setOpenResultDate={setOpenResultDate}
            setOpenClassResult={setOpenClassResult}
            selectClass={selectClass}
            title={'Primary'}
            classes={primary}
            setCategory={setCategory}
            refresh={refreshList}
            examSchema={examSchemas?.data?.find((x) => x.category === 'Primary')}
          />

          {/* Middle */}
          <ExaminationInchargeClassTable
            setOpenExamScheme={setOpenExamScheme}
            setOpenResultDate={setOpenResultDate}
            setOpenClassResult={setOpenClassResult}
            selectClass={selectClass}
            title={'Middle'}
            classes={middle}
            setCategory={setCategory}
            refresh={refreshList}
            examSchema={examSchemas?.data?.find((x) => x.category === 'Middle')}
          />

          {/* Secondary */}
          <ExaminationInchargeClassTable
            setOpenExamScheme={setOpenExamScheme}
            setOpenResultDate={setOpenResultDate}
            setOpenClassResult={setOpenClassResult}
            selectClass={selectClass}
            title={'Secondary'}
            classes={secondary}
            setCategory={setCategory}
            refresh={refreshList}
            examSchema={examSchemas?.data?.find((x) => x.category === 'Secondary')}
          />

          {/* Senior - Secondary */}
          <ExaminationInchargeClassTable
            setOpenExamScheme={setOpenExamScheme}
            setOpenResultDate={setOpenResultDate}
            setOpenClassResult={setOpenClassResult}
            selectClass={selectClass}  
            setCategory={setCategory}
            refresh={refreshList}
            classes={seniorSecondary}
            title={'Senior - Secondary'}
            examSchema={examSchemas?.data?.find((x) => x.category === 'Senior-Secondary')}
          />
          {/* Senior - Secondary */}
          <ExaminationInchargeClassTable
            setOpenExamScheme={setOpenExamScheme}
            setOpenResultDate={setOpenResultDate}
            setOpenClassResult={setOpenClassResult}
            selectClass={selectClass}  
            setCategory={setCategory}
            refresh={refreshList}
            classes={graduation}
            title={'Graduation'}
            examSchema={examSchemas?.data?.find((x) => x.category === 'Graduation')}
          />
          <input
            type="file"
            accept=".csv"
            hidden
            ref={csvRef}
            onChange={(e) => {
              setCsvFile(e.target.files[0]);
            }}
          ></input>
          {/* Examination Dates */}
          <div className="w-full bg-white rounded-xl h-auto mb-5">
            <div className="items-center px-6 py-3 border-b border-borderYellow">
              <div className="lg:flex block sm:block justify-between items-center">
                <div className="font-bold w-6/12">
                  <p>Examination Dates</p>
                </div>
                <div className="flex justify-end items-center w-full gap-x-6">
                  <div className="float-right">
                    <ExcelExportBtn
                      data={[]}
                      fileName={'student_CSV.csv'}
                      text={'Download CSV Template'}
                      width={'w-40'}
                      headers={csvTemplate}
                    />
                  </div>
                  <DropDown
                    data={['All', 'Pre - Primary', 'Primary', 'Middle', 'Secondary', 'Senior - Secondary', 'Graduation' ]}
                    width={'w-1/4'}
                    title={'Sort By'}
                    handleItemClick={(x) => {
                      setFilter(x);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-wrap w-full h-1/4">
              <div className="w-full py-4 overflow-auto">
                <div
                  className="bg-white m-auto border rounded-2xl border-borderYellow overflow-hidden min-w-700"
                  style={{ width: '96%' }}
                >
                  <div className="w-full flex bg-tableHeader rounded-3xl">
                    <div className="flex w-full relative text-left px-4 text-xs font-medium">
                      <div className="w-1/5 border-r-2 py-2 px-2">Class</div>
                      <div className="w-1/5 border-r-2 py-2 px-2">Section</div>
                      <div className="w-1/5 border-r-2 py-2 px-2">Class Teacher</div>
                      <div className="w-1/5 border-r-2 py-2 px-2">Upload </div>
                      <div className="w-1/5 py-2 px-2">Action</div>
                    </div>
                  </div>
                  <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '50vh' }}>
                    {_classes.length == 0 ? (
                      <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">
                        No Data Available
                      </p>
                    ) : (
                      _classes.map((x, idx) => (
                        <div className="w-full">
                          <div
                            className={`justify-center flex w-full relative text-left py-3 px-4 text-xs font-medium items-center ${
                              idx !== _classes.length - 1 && 'border-b border-borderYellow'
                            }`}
                          >
                            <div className="w-1/5 px-2 text-textDark">{x.class}</div>
                            <div className="w-1/5 px-2 text-textDark">{x.section}</div>
                            <div className="w-1/5 px-2 text-textDark">{x.headTeacher || 'Not Assigned'}</div>
                            <div className="w-1/5 px-2">
                              {selectedClassForCSV &&
                              x.sectionId === selectedClassForCSV.sectionId &&
                              (postDatesResponse.isLoading || deleteDatesResponse.isLoading) ? (
                                <img src="assets/img/loader.svg" className="w-12 m-auto " alt="" />
                              ) : (
                                <button
                                  type="submit"
                                  className="border border-primary w-9/12 py-2 rounded-3xl text-primary font-medium mx-1 focus:outline-none flex justify-center text-center items-center text-xs"
                                  onClick={() => {
                                    if (x.examDates) handleDelete(x);
                                    else handleFileSelect(x);
                                  }}
                                >
                                  {x.examDates ? 'Remove' : 'Upload CSV'}
                                </button>
                              )}
                            </div>
                            <div className="w-1/5 px-2">
                              {x.examDates ? (
                                <button
                                  type="submit"
                                  className="border border-primary w-9/12 py-2 rounded-3xl text-primary font-medium mx-1 focus:outline-none flex justify-center text-center items-center text-xs"
                                  onClick={() => selectClassForDates(x)}
                                >
                                  View
                                </button>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ExaminationIncharge;
