import React, { useState, useEffect } from 'react';
import MapStudent from '../../../components/Admin/StudentManagement/MapStudent';
import DropDown from '../../../components/UI/DropDown';
import ExcelExportBtn from '../../../components/UI/ExcelExportBtn';
import { GET_UNMAPPED_STUDENTS } from '../../../api/urls/instituteManagement';
import useApi from '../../../api/useApi';
import moment from 'moment';

function Admissions() {
  const [selectedStudent, selectStudent] = useState();
  const [unMappedStudents, setUnMappedStudents] = useState([]);
  const [openMapStudent, setOpenMapStudent] = useState(false);

  const [unMappedStudentState, getUnMappedStudents] = useApi();

  useEffect(() => {
    getUnMappedStudents(GET_UNMAPPED_STUDENTS, 'GET');
  }, []);

  useEffect(() => {
    if (!unMappedStudentState.isLoading && !unMappedStudentState.isError && unMappedStudentState.data) {
      setUnMappedStudents(unMappedStudentState.data.students);
    }
  }, [unMappedStudentState]);

  const refreshList = () => {
    getUnMappedStudents(GET_UNMAPPED_STUDENTS, 'GET');
  };

  const toggleMapStudent = () => {
    setOpenMapStudent((prev) => !prev);
  };

  const selectStudentToMap = (x) => {
    selectStudent(x);
    toggleMapStudent();
  };

  return (
    <div className="w-full md:w-4/5">
      {openMapStudent && (
        <MapStudent toggleMapStudent={toggleMapStudent} selectedStudent={selectedStudent} refreshList={refreshList} />
      )}
      <div className="relative w-full pb-10">
        <div className="w-full bg-white rounded-xl h-auto">
          <div className="items-center px-6 py-3 border-b border-borderYellow">
            <div className="lg:flex block sm:block justify-between items-center">
              <div className="font-bold text-base w-2/5">
                <p>New Students</p>
              </div>

              <div className="flex justify-end w-full md:w-3/5 gap-x-6">
                {/* <ExcelExportBtn data={[]} text={'Export Data'} fileName={'students.csv'} /> */}
                <DropDown
                  data={['All', 'Pre - Primary', 'Primary', 'Middle', 'Secondary', 'Senior Secondary', 'Graduation']}
                  width={'w-1/4'}
                  title={'Sort By'}
                  handleItemClick={() => {}}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-wrap w-full h-1/4">
            <div className="w-full py-4 overflow-auto">
              <div className="bg-white m-auto border rounded-2xl mx-5 border-borderYellow overflow-hidden min-w-700">
                <div className="w-full flex bg-tableHeader rounded-3xl">
                  <div className="flex w-full relative text-xs font-medium px-2">
                    <div className="w-1/5 border-r-2 py-2 px-2">Student Name</div>
                    <div className="w-1/5 border-r-2 py-2 px-2">Date of Admission</div>
                    <div className="w-1/5 border-r-2 py-2 px-2">Class</div>
                    <div className="w-1/5 border-r-2 py-2 px-2">Status</div>
                    <div className="w-1/5 py-2 px-2">Action</div>
                  </div>
                </div>
                <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '75vh' }}>
                  {unMappedStudentState.isLoading ? (
                    <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
                  ) : Array.isArray(unMappedStudents) && unMappedStudents.length > 0 ? (
                    unMappedStudents.map((x, idx) => (
                      <div className="w-full">
                        <div
                          className={`justify-center items-center flex w-full relative py-2 px-2 text-xs font-medium ${
                            unMappedStudents.length - 1 !== idx && 'border-b border-borderYellow'
                          }`}
                        >
                          <div className="w-1/5 px-2 text-textDark">{x.full_name || x.name}</div>
                          <div className="w-1/5 px-2 text-tableRowText">
                            {' '}
                            {moment(x.createdAt).format('ll') || moment(x.joined).format('ll')}
                          </div>
                          <div className="w-1/5 px-2 text-tableRowText">--</div>
                          <div className="w-1/5 px-2 text-textDark">Class Not Mapped</div>

                          <div className="w-1/5 flex justify-center items-center pr-6">
                            <button
                              className="w-10/12 border border-primary text-primary px-4 py-2 rounded-3xl text-xs focus:outline-none"
                              onClick={() => {
                                selectStudentToMap(x);
                              }}
                            >
                              Map Class
                            </button>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Admissions;
