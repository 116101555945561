import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import CalenderTabs from './CalenderTabs';
import useApi from '../../../api/useApi';
import { CREATE_CALANDER, GET_CALANDER , POST_SemesterEvents , GET_SemesterEvents  } from '../../../api/urls/institute';
import moment from 'moment';
import CustomToolbar from './CustomToolBar';
import { useStateValue } from '../../../ContextApi/StateProvider';

const AcademicCalender = () => {
  const [showCalender, setShow] = useState(false);
  const [calanderId, setCalanderId] = useState('');
  const [calanderData, setCalanderData] = useState(false);
  const [perm, setperm ] = useState(false);
  const [IsSemester , enableSemesterControl ] = useState(false);
  const [IsSemesterEvent , AddSemesterEvents ]  = useState(null);
  const [SemesterEventsData ,setSemesterEventsData ] = useState([]);

  const currentYear = moment().year();
  const startDate = moment(`${currentYear}-04-01`).toDate();
  const endDate = moment(`${currentYear + 1}-03-31`).toDate();

  const localizer = momentLocalizer(moment);

  const [holidays, createCalander] = useApi();
  const [calanderRes, getCalander] = useApi();
  const [GetSemesterEventsRes, GetSemesterEventsReq] = useApi();
  const [DeleteSemsterEventsRes, DeleteSemsterEventsReq] = useApi();
  

  const [{ user, openMenu }, dispatch] = useStateValue();

  const handleClick = () => {
    let CurRole = GetCurrRole()  ;
    createCalander(CREATE_CALANDER(CurRole), 'POST', {});
  };

  useEffect(() => {
    getCalander(GET_CALANDER, 'GET');
    GetSemesterEventsReq(GET_SemesterEvents, 'GET');
  }, []);

  useEffect(() => {
    if (holidays.status === 200) {
      setShow(true);
      setCalanderId(holidays?.data?._id);
    }

    if (holidays.isError) {
      alert(typeof holidays?.data === 'string' ? holidays?.data : 'Some error occured');
    }
  }, [holidays]);
  //
  useEffect(() => {
    if( !GetSemesterEventsRes?.isLoading){ 
      if( GetSemesterEventsRes?.isError || GetSemesterEventsRes?.data?.Error ){
        alert("Something unexpected happans." ) ;
      } else if( GetSemesterEventsRes?.data?.noError ){
        // console.log(" -- GetSemesterEventsRes : ", GetSemesterEventsRes?.data ) ; 
        if( Array.isArray(GetSemesterEventsRes?.data?.allData) ){
          let temp_data1 = GetSemesterEventsRes?.data?.allData.map( (dataobject, index) => { 
            let tempEventType = "Other" ; 
            if( dataobject.semEveType == "1" ){ tempEventType = "Semester Start" ; }
            else if( dataobject.semEveType == "2" ){ tempEventType = "Semester End" ; }
            else if( dataobject.semEveType == "3" ){ tempEventType = "Examination" ; }
            else if( dataobject.semEveType == "4" ){ tempEventType = "Holidays" ; }
            else if( dataobject.semEveType == "5" ){ tempEventType = "Other" ; }
            // 
            return { dataobject : dataobject,SemesterEventDate: dataobject.semEveDate, SemesterEventName : dataobject.semEveName ,SemesterEventType:tempEventType ,SemesterEventWorking : dataobject.semEveSubType == "1"  }
          }) ; 
          temp_data1.sort((a, b) => new Date(a.SemesterEventDate) - new Date(b.SemesterEventDate) );
          setSemesterEventsData( temp_data1 ) ;
        }
      }
    } 
  }, [GetSemesterEventsRes]);

  const handleShowMainCalander = (data) => {
    setCalanderData(data);
  };
  const GetCurrRole = () => {
    let checkPerm = user.roles
    if( checkPerm.length > 0 ){ checkPerm = checkPerm[0].role }
    else{ checkPerm = "" ;  }
    // console.log(" curr role : ", checkPerm ) ;
    return checkPerm ;
  }
  useEffect(() => {  // console.log(" -- calanderRes : ", calanderRes )
    if( calanderRes && calanderRes?.data ){
      if ( Array.isArray(calanderRes.data?.cal_list) && calanderRes.data?.cal_list.length > 0 ) {
        setCalanderData(calanderRes?.data?.cal_list);
      }
      if(Array.isArray(calanderRes.data?.perm) ){
        let CurRole = GetCurrRole()  ;
        setperm(calanderRes.data?.perm.includes(CurRole) );
      }
      if( calanderRes.data?.canCreateSemester ){ 
        enableSemesterControl( true );
      }
    } 
  }, [calanderRes]);
  //
  useEffect(() => {
    if( !DeleteSemsterEventsRes?.isLoading){ 
      if( DeleteSemsterEventsRes?.isError || DeleteSemsterEventsRes?.data?.Error ){
        alert("Something unexpected happans.\n" ) ;
      } else if( DeleteSemsterEventsRes?.data?.noError ){ // console.log(" -- DeleteSemsterEventsRes : ", DeleteSemsterEventsRes?.data ) ; 
        if ( DeleteSemsterEventsRes?.data?.DataMod == "3"  )
        { alert("Semester Event Deleted Sucessfully.\n"); CloseSemsterInput();   }
      }
    } 
  }, [DeleteSemsterEventsRes]);

  const eventPropGetter = useCallback(
    (event, start, end, isSelected) => ({
      ...(event.eventType === 'Gazetted Holiday' && {
        className: 'gazettedHolidayColor',
      }),
      ...(event.eventType === 'Regional Holiday' && {
        className: 'regionalHolidayColor',
      }),
      ...(event.eventType === 'Restricted Holiday' && {
        className: 'restrictedHolidayColor',
      }),
      ...(event.eventType === 'State Holiday' && {
        className: 'stateHolidayColor',
      }),
      ...(event.eventType === 'Other Holiday' && {
        className: 'otherHolidayColor',
      }),
      ...(event.eventType === 'Summer Holidays' && {
        className: 'summerHolidayColor',
      }),
      ...(event.eventType === 'Winter Holidays' && {
        className: 'winterHolidayColor',
      }),
    }),
    []
  );

  const calEvents = [];
  Array.isArray(calanderData)
    ? calanderData.forEach((x) => {
        x.events.forEach((event) => {
          calEvents.push({
            title: event?.key,
            start: new Date(x.date),
            eventType: event.eventType || x?.type,
            end: new Date(x.date),
          });
        });
      })
    : [];

  if (calanderRes.isLoading) return <img src="assets/img/loader.svg" className="my-10 mx-auto" />;
  // let SemesterEventsisLoading = false 
  // let SemesterEventsData = [ {SemesterEventName : "Math-103 Exam" ,SemesterEventType:"Examination" ,SemesterEventWorking : true },
  //   {SemesterEventName : "Chemistry-101 Exam" ,SemesterEventType:"Examination" ,SemesterEventWorking : true },
  //   {SemesterEventName : "Semster End holidays" ,SemesterEventType:"Holidays" ,SemesterEventWorking : false },
  //   {SemesterEventName : "Farewell Party" ,SemesterEventType:"Others" ,SemesterEventWorking : true }
  // ] ;

  const editSemesterEvent = (dataObject) => {  // console.log(" edit dataObject : ", dataObject ) ;
    AddSemesterEvents( dataObject?.dataobject )
  } 
  const deleteSemesterEvent = (dataObject) => { 
    // console.log(" delete dataObject : ", dataObject?.dataobject ) ;
    let ReqData = {} ;
    ReqData._id = dataObject?.dataobject?._id ;
    ReqData.delete = true ;
    //
    DeleteSemsterEventsReq( POST_SemesterEvents , 'POST', ReqData );
  } 
  //
  const CloseSemsterInput = () => {
    AddSemesterEvents(null); 
    getCalander(GET_CALANDER, 'GET'); 
    GetSemesterEventsReq(GET_SemesterEvents, 'GET'); 
  }
  //
  return (
    <div className="w-full md:w-4/5">
      <div className="relative pb-10">
        <div className="w-full bg-white rounded-xl h-auto">
          <div className="items-center px-6 py-3 border-b border-borderYellow mb-1">
            <p className="text-center font-semibold text-lg">Academic Calander 2024 - 2025</p>
          </div>

          {calanderData ? (
            <Calendar
              localizer={localizer}
              events={calEvents}
              style={{ height: 500 }}
              views={['month']}
              eventPropGetter={eventPropGetter}
              min={startDate}
              max={endDate}
              components={{
                toolbar: CustomToolbar, // Use the custom toolbar
              }}
            />
          ) : !perm ? (<div className="py-6 text-center"> Academic Calander Not Created</div> ) 
          : !showCalender ? (
            <div className="py-6">
              <button
                className="bg-bgBlue text-white flex items-center gap-x-2 text-sm rounded-full px-6 py-2 mx-auto"
                onClick={handleClick}
                disabled={holidays.isLoding}
              >
                <img src="assets/img/AI.svg" />
                Create Academic Calendar
              </button>
            </div>
          ) : (
            <CalenderTabs calanderId={calanderId} showCalander={handleShowMainCalander} />
          )} 
        </div>
        {calanderData && IsSemester && !IsSemesterEvent ? 
        <div className="w-full bg-white rounded-xl h-auto mt-6">
          <div className="flex flex-col md:flex-row justify-between md:items-center">
              <div className="font-bold w-full md:w-1/3 ml-4">
                <p> Semester  Events  </p>
              </div>
              <div className="flex gap-x-4 w-full md:w-2/3 justify-end mt-4 mr-6">
                <button
                  className="w-40 border py-2 rounded-3xl text-xs text-white bg-primary border-primary  focus:outline-none flex justify-center items-center"
                  onClick={() => AddSemesterEvents( { } )}
                >
                  Add Semester Events
                </button>
              </div>
          </div>
          <div className="flex flex-wrap w-full h-1/4 px-6">
            <div className="w-full py-4 overflow-auto">
              <div className="bg-white m-auto border rounded-2xl border-borderYellow min-w-700">
                <div className="w-full flex bg-tableHeader rounded-3xl">
                  <div className="flex w-full relative text-left px-4 text-xs font-medium">
                  <div className="w-2/12 border-r-2 py-2 px-2">Date</div>  
                    <div className="w-3/12 border-r-2 py-2 px-2">Semester Event Name</div>
                    <div className="w-3/12 border-r-2 py-2 px-2">Semester Event Type</div>
                    <div className="w-2/12 border-r-2 py-2 px-2">Working / Non-Working</div>
                    <div className="w-2/12 py-2 px-2">Action</div>
                  </div>
                </div>
                <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '55vh' }}>
                  { GetSemesterEventsRes.isLoading ? (
                    <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
                  ) : SemesterEventsData.length > 0 ? (
                    SemesterEventsData.map((x, idx) => {
                      return (
                        <div className="w-full">
                          <div
                            className={`justify-center flex w-full relative text-left py-4 px-4 text-xs font-medium items-center ${
                              idx !== SemesterEventsData.length - 1 && 'border-b border-borderYellow'
                            }`}
                          >  
                            <div className="w-2/12 px-2">{x?.SemesterEventDate }</div>
                            <div className="w-3/12 px-2">{x?.SemesterEventName }</div>
                            <div className="w-3/12 px-2 text-tableRowText capitalize">{x?.SemesterEventType }</div>
                            <div className="w-2/12 px-2 text-tableRowText">{x?.SemesterEventWorking ? "Working" : "Non-Working" }</div>
                            <div className="w-2/12 flex items-center px-2">
                              <button
                                className="w-11/12 border mr-2 py-2 border-primary text-primary rounded-3xl text-xs focus:outline-none"
                                onClick={() => editSemesterEvent(x) }
                              >
                                Edit
                              </button>
                              <button
                                className="w-11/12 border  py-2 border-primary text-primary rounded-3xl text-xs focus:outline-none"
                                onClick={() => deleteSemesterEvent(x) }
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        : IsSemesterEvent ? 
        <AddModifySemsterEvents eventData={IsSemesterEvent} onClose={ CloseSemsterInput } calanderUpdate={() => { getCalander(GET_CALANDER, 'GET');  } } />
        :  null } 
      </div>
    </div>
  );
};

export default AcademicCalender;


const AddModifySemsterEvents = ({eventData, onClose, calanderUpdate}) => { 
  const attachment = useRef();
  const formModel = { semEveType: '1', semEveSubType: '1', semEveDate: '',description:"",semEveName:"", files: null, semEvedisAbleSubType : "1" };
  const [formData, setFormData] = useState(formModel); 
  // 
  const [AddModifySemsterEventsRes, AddModifySemsterEventsReq] = useApi();
  // 
  useEffect(() => { 
    if( eventData._id ){ console.log(" update data here")
      setFormData({ semEveType: eventData.semEveType
        , semEveSubType: eventData.semEveSubType
        , semEveDate: new Date( eventData.semEveDate )
        , description: eventData.description
        , semEveName: eventData.semEveName
        , files: null
        , semEvedisAbleSubType : eventData.semEveType == "5" ? "" : "1" })
    }
  }, [ ]);
  //
  const handleInputChange = (e) =>{   
    let tempData = { ...formData, [e.target.name]: e.target.value } ; 
    if( e.target.name == "semEveType"){
      if( e.target.value == "1" || e.target.value == "2" || e.target.value == "3" )
      { tempData.semEveSubType = "1"  } // Working 
      else if( e.target.value == "4" )
      { tempData.semEveSubType = "2"  }
      //
      if( e.target.value == "5" )
      { tempData.semEvedisAbleSubType = "" ;  }
      else { tempData.semEvedisAbleSubType = "1" ;  }
    }
    setFormData(tempData); 
  } 
  //
  const EmmptyDataAll = () => {
    attachment.current.value = '';
    setFormData( formModel );
  }
  const handleSemesterEventsData = (e) => { 
    e.preventDefault();
    console.log(" handleSemesterEventsData : ", formData ) ; 
    let ReqData = JSON.parse(JSON.stringify(formData)); ;
    // try{    } catch { console.log(" --- "); }
    ReqData.semEveDate = moment(ReqData.semEveDate).format('YYYY-MM-DD'); 
    ReqData._id = eventData._id ? eventData._id : "0" ;
    ReqData.delete = false ;
    //
    AddModifySemsterEventsReq( POST_SemesterEvents , 'POST', ReqData );
  } 
  // 
  useEffect(() => {
    if( !AddModifySemsterEventsRes?.isLoading){ 
      if( AddModifySemsterEventsRes?.isError || AddModifySemsterEventsRes?.data?.Error ){
        alert("Something unexpected happans.\n" ) ;
      } else if( AddModifySemsterEventsRes?.data?.noError ){ // console.log(" -- AddModifySemsterEventsRes : ", AddModifySemsterEventsRes?.data ) ; 
        if( AddModifySemsterEventsRes?.data?.ShowAlertMsg  )
        { alert(AddModifySemsterEventsRes?.data?.ShowAlertMsg); }
        else if ( AddModifySemsterEventsRes?.data?.DataMod == "1"  )
        { EmmptyDataAll() ;  alert("Semester Event Saved Sucessfully.\n"); if(calanderUpdate){ calanderUpdate(); } }
        else if ( AddModifySemsterEventsRes?.data?.DataMod == "2"  )
        { alert("Semester Event Modified Sucessfully.\n"); if( onClose ){ onClose(); }   }
      }
    } 
  }, [AddModifySemsterEventsRes]);
  //
  return (
    <div className="w-full bg-white rounded-xl h-auto mt-6"> 
        <div className="items-center py-3">
          <div
            className="flex w-full justify-between items-center relative px-6"
            style={{ borderBottom: '0.71px solid #E7E700' }}
          >
            <p className="block font-bold  pt-1 pb-4 text-textBlue1">Add Semester Events</p>
          </div>
        </div>
        <div className="flex w-full h-1/4 px-4 pb-6">
          <form className="w-full gap-x-6 grid grid-cols-1 md:grid-cols-2 px-4 pb-2" onSubmit={handleSemesterEventsData}>
            <div className="my-2  relative font-semibold w-full">
              <label className="block  text-sm text-textDark">
                Select Events Type<span className="text-red-500 ml-1">*</span>
              </label>
              <div className="w-full text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none">
                <select
                  type="text"
                  name="semEveType"
                  required
                  // placeholder="Eg-Earned"
                  className="w-full"
                  onChange={handleInputChange}
                  value={formData.semEveType}
                >
                  <option value={"1"}>Semester Start</option>
                  <option value={"2"}>Semester End</option>
                  <option value={"3"}>Examination</option>
                  <option value={"4"}>Holidays</option>
                  <option value={"5"}>Other</option>
                  {/* {Array.isArray(leaveTypes) &&
                    leaveTypes.map((x) => <option value={x?.leave._id}>{x?.leave?.leave_type}</option>)} */}
                </select>
              </div>
            </div>

            <div className="my-2 relative font-semibold w-full">
              <label className="block  text-sm text-textDark">Event Sub-Type</label>
              { formData.semEvedisAbleSubType == "1" ?
              <div className="w-full text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none"> { formData.semEveSubType == "1" ? 'Working' : 'Non-Working' } </div>
              : <div className="w-full text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none" >
                <select
                  type="text"
                  name="semEveSubType"
                  required 
                  // placeholder="Eg-Earned"
                  className="w-full"
                  onChange={handleInputChange}
                  value={formData.semEveSubType}
                >
                  <option value={"1"}>Working</option>
                  <option value={"2"}>Non-Working</option> 
                </select>
              </div> }
            </div>
            <div className="my-2 relative font-semibold w-full">
              <label className="block  text-sm text-textDark">
                Event Date<span className="text-red-500 ml-1">*</span>
              </label>
              <DatePicker
                className="w-full text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                selected={formData.semEveDate}
                onChange={(date) => setFormData({ ...formData, semEveDate: date })}
                required 
                dateFormat={'yyyy-MM-dd'}
              />
            </div> 
            <div className="my-2 relative font-semibold w-full">
              <label className="block  text-sm text-textDark">
                Event Name<span className="text-red-500 ml-1">*</span>
              </label>
              <input
                type="text"
                name="semEveName"
                placeholder="Event Name" 
                value={formData.semEveName} 
                className="w-full rounded-2xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                onChange={handleInputChange} 
                required
              />
            </div> 
            <div className="my-2 w-full relative font-semibold md:col-span-2">
              <label className="block  text-sm text-textDark">Description</label>
              <textarea
                type="text"
                name="description"
                placeholder="Add more details about the event"
                rows="4"
                value={formData.description}
                cols="50"
                className="w-full rounded-2xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                onChange={handleInputChange}
              />
            </div>
            <div className="my-2 relative font-semibold w-full md:w-1/2 md:col-span-2" hidden >
              <label className="block  text-sm text-textDark">Attachment</label>
              <div className="relative flex">
                <input
                  hidden
                  ref={attachment}
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={(e) => setFormData({ ...formData, files: e.target.files[0] })}
                  className="w-full  rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                />
                <input
                  // required
                  type="text"
                  disabled
                  value={formData.files?.name}
                  className="w-full  rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                />
                <button
                  className="px-10 py-2 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center mt-2 absolute right-0 bottom-0 top-0"
                  onClick={(e) => {
                    e.preventDefault();
                    attachment.current.click();
                  }}
                >
                  Upload
                </button>
              </div>
            </div>

            <div className="w-full justify-end md:col-span-2">
              {AddModifySemsterEventsRes?.isLoading ? (
                <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
              ) : (
                <div className="flex w-full justify-end mt-6">
                  <button
                    type="reset"
                    className="border border-tableRowText text-tableRowText px-8 py-2 text-xs rounded-3xl font-medium mx-1 focus:outline-none flex justify-center text-center items-center"
                    onClick={(e) => {
                      if( onClose ){ onClose(); } 
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="border px-8 py-2 text-xs rounded-3xl bg-primary font-medium text-white mx-1 focus:outline-none flex justify-center text-center items-center"
                  >
                    Submit
                  </button>
                </div>
              )}
            </div>
          </form>
        </div>
    </div>
  )
}