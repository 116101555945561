import React, { useState, useEffect } from 'react';
import useApi from '../../../api/useApi';
import { CLASS } from '../../../api/urls/institute';
import DropDown from '../../UI/DropDown';

function AddClass({ onClose, tabName, selectedClass = '', refreshList }) {
  const [className, setClass] = useState(selectedClass);
  const [classgroupID, setgroupID] = useState("0");

  const [{ isLoading, isError, data, status }, addUpdateClass] = useApi();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!className?.trim()) {
      alert('Please add class name');
      return;
    }
    // let category = '';
    // if (className.toLowerCase().includes('kg') || className.toLowerCase().includes('nursery')) {
    //   category = 'PrePrimary';
    // } else if (className.includes(11) || className.includes(12)) {
    //   category = 'Senior-Secondary';
    // } else if (className.includes(9) || className.includes(10)) {
    //   category = 'Secondary';
    // } else if (className.includes(6) || className.includes(7) || className.includes(8)) {
    //   category = 'Middle';
    // } else if (
    //   className.includes(1) ||
    //   className.includes(2) ||
    //   className.includes(3) ||
    //   className.includes(4) ||
    //   className.includes(5)
    // ) {
    //   category = 'Primary';
    // }

    let body;
    let method;
    if (tabName === 'Add') {
      body = {
        name: className,
        // category,
        groupID : classgroupID,
      };
      method = 'POST';
    } else {
      body = {
        name: selectedClass,
        Cname: className,
        // category,
        groupID : classgroupID,
      };
      method = 'PUT';
    }
    addUpdateClass(CLASS, method, body);
  };

  useEffect(() => {
    if (!isLoading && !isError && status) {
      setClass('');
      onClose();
      refreshList();
      alert('Successfully added');
    }

    if (isError) {
      alert('Failed to add class');
    }
  }, [isLoading, isError, data, status]);

  return (
    <div
      className="flex justify-center  h-full w-full fixed left-0 top-0 z-30"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-11/12 md:w-4/12 mb-auto mt-52 rounded-2xl relative">
        <span className="block font-bold py-4 px-6" style={{ borderBottom: '0.71px solid #E7E700' }}>
          {tabName} Class
        </span>
        <img
          src="assets/img/Close.svg"
          className="absolute top-4 mt-1 right-4 h-3 cursor-pointer"
          alt=""
          onClick={() => onClose()}
        />
        <form className="mt-2 mb-4 mx-4 flex flex-col px-2" autoComplete="off" onSubmit={handleSubmit}>
          <div className="mt-2 mb-4 w-full relative font-semibold">
            <label className="block text-parameter text-sm">
              Class Name<span className="text-red-500 ml-1">*</span>
            </label>
            <input
              type="text"
              placeholder="Eg- 10th"
              autoComplete="off"
              required
              value={className}
              onChange={(e) => setClass(e.target.value)}
              className="w-full text-xs rounded-3xl border px-4 mt-2 py-2.5 focus:outline-none"
            />
          </div>
          <div className="w-full mb-4 ">
              <label htmlFor="category" className="block text-parameter text-sm">
                Select Group
              </label>
              <DropDown
                //  width={'w-1/3'}
                title={'Select Group'}
                data={[{"name" : "Pre Primary","ID":"1" },{"name" : "Primary","ID":"2" },{"name" : "Middle","ID":"3" },{"name" : "Secondary","ID":"4" },{"name" : "Senior - Secondary","ID":"5" },{"name" : "Graduation","ID":"6" } ]}
                field={'name'}
                type={'obj'}
                handleItemClick={(x) => { // console.log(" check click : ", x );
                  setgroupID(x?.ID) ; // selectInstitution(x._id);
                }}
                margin={'mt-4 mx-auto'}
              />
          </div>

          <div className="flex justify-end">
            {isLoading ? (
              <img src="assets/img/loader.svg" className="w-14 ml-auto" alt="" />
            ) : (
              <button
                type="submit"
                className="mt-1 lg:mt-0 w-auto lg:w-max border px-12 py-4 rounded-3xl text-xs font-bold focus:outline-none flex justify-center items-center h-7 bg-primary text-white"
              >
                {tabName === 'Add' ? 'Add Class' : 'Save'}
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddClass;
